const BACKEND_URI = "";

import { ChatAppRequest, SearchRequest, DocumentResult } from "./models";
import { FileInfo, Project } from "../components/ProjectsComponents/types";

function getHeaders(idToken: string | null): Record<string, string> {
    const headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    if (idToken) {
        headers["Authorization"] = `Bearer ${idToken}`;
    }

    return headers;
}

export async function chatApi(request: ChatAppRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function chatPdfApi(request: ChatAppRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chatpdf`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function listConversations(idToken: string | null, page: number, starred: boolean | null = false): Promise<Response> {
    const resp = await fetch(`${BACKEND_URI}/conversation?page=${page}&starred=${starred}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
    return resp;
}

export async function getConversation(id: string, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/conversation/${id}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
}

export async function getMonthlyMessagesCount(idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/messages/monthly-count`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
}

export async function getUser(idToken: string | null, invite_code?: string | null, country?: string | null): Promise<Response> {
    let url = `${BACKEND_URI}/user`;
    const params = new URLSearchParams();
    if (invite_code) {
        params.append("invite_code", invite_code);
    }
    if (country) {
        params.append("country", country);
    }
    if (params.toString()) {
        url += `?${params.toString()}`;
    }
    return await fetch(url, {
        method: "GET",
        headers: getHeaders(idToken)
    });
}

export async function updateUser(idToken: string | null, updateFields: Record<string, any>): Promise<Response> {
    return await fetch(`${BACKEND_URI}/user/update`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(updateFields)
    });
}

export async function searchRequest(request: SearchRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/search`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

// Updated functions to use fetch and idToken parameter
export async function uploadFile(
    file: File,
    projectId: string,
    idToken: string | null
): Promise<{
    file_info: FileInfo;
}> {
    const headers = getHeaders(idToken);
    delete headers["Content-Type"]; // Let the browser set this automatically
    const formData = new FormData();
    formData.append("file", file);
    formData.append("project_id", projectId);

    const response = await fetch(`${BACKEND_URI}/upload-file`, {
        method: "POST",
        headers,
        body: formData
    });

    if (!response.ok) {
        throw new Error(`Error uploading file: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
}

export async function deleteFile(id: string, idToken: string | null): Promise<void> {
    const response = await fetch(`${BACKEND_URI}/file/${id}`, {
        method: "DELETE",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Error deleting file: ${response.statusText}`);
    }
}

export async function getFileUrl(filename: string, folderId: string, idToken: string | null): Promise<string> {
    const response = await fetch(`${BACKEND_URI}/view/${folderId}/${filename}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Error retrieving file URL: ${response.statusText}`);
    }

    const data = await response.json();
    return data.url;
}

export async function createProject(name: string, description: string, idToken: string | null): Promise<Project> {
    const response = await fetch(`${BACKEND_URI}/project`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify({ name, description })
    });

    if (!response.ok) {
        throw new Error(`Error creating folder: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
}

export async function deleteProject(projectId: string, idToken: string | null): Promise<void> {
    const response = await fetch(`${BACKEND_URI}/project/${projectId}`, {
        method: "DELETE",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Error deleting folder: ${response.statusText}`);
    }
}

export async function getProject(id: string, idToken: string | null): Promise<Project> {
    const response = await fetch(`${BACKEND_URI}/project/${id}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Error retrieving folder details: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
}

export async function listProjects(idToken: string | null): Promise<Project[]> {
    const response = await fetch(`${BACKEND_URI}/project`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Error listing folders: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
}

export async function submitFeedback(messageId: number, isPositive: boolean, feedbackText: string | null, token: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/feedback`, {
        method: "POST",
        headers: getHeaders(token),
        body: JSON.stringify({
            message_id: messageId,
            is_positive: isPositive,
            feedback_text: feedbackText
        })
    });
}

export async function toggleConversationFavorite(id: string, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/conversation/${id}/favorite`, {
        method: "POST",
        headers: getHeaders(idToken)
    });
}

export const getDocuments = async (
    token: string | null,
    _hideDeleted: boolean = false,
    starred: boolean = false,
    page: number = 1,
    pageSize: number = 20
): Promise<Response> => {
    if (!token) {
        throw new Error("No authentication token provided");
    }

    const headers = getHeaders(token);

    const queryParams = new URLSearchParams({
        starred: starred.toString(),
        page: page.toString(),
        page_size: pageSize.toString(),
        _t: Date.now().toString()
    });

    return await fetch(`${BACKEND_URI}/documents?${queryParams.toString()}`, {
        method: "GET",
        headers: headers
    });
};

export const toggleDocumentFavorite = async (docId: string, token: string | null): Promise<Response> => {
    if (!token) {
        throw new Error("No authentication token provided");
    }

    return await fetch(`${BACKEND_URI}/document/${docId}/favourite`, {
        method: "POST",
        headers: getHeaders(token)
    });
};

export async function markDocumentViewed(docId: string, documentData: Partial<DocumentResult>, idToken: string | null): Promise<Response> {
    const headers = getHeaders(idToken);
    return await fetch(`${BACKEND_URI}/document/${docId}/view`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(documentData)
    });
}

export async function completeTour(idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/user/tour/complete`, {
        method: "POST",
        headers: getHeaders(idToken)
    });
}

export async function chatProjectApi(request: ChatAppRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chatproject`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function saveDocument(
    data: {
        doc_id: string;
        project_id: string;
    },
    idToken: string | null
): Promise<Response> {
    return await fetch(`${BACKEND_URI}/document`, {
        headers: getHeaders(idToken),
        method: "POST",
        body: JSON.stringify(data)
    });
}

export async function removeSavedDocument(projectId: string, documentId: string, idToken: string | null): Promise<void> {
    const response = await fetch(`${BACKEND_URI}/project/${projectId}/document/${documentId}`, {
        method: "DELETE",
        headers: getHeaders(idToken)
    });

    if (!response.ok) {
        throw new Error(`Error removing saved document: ${response.statusText}`);
    }
}
