import React from "react";
import { Box, List } from "@mui/joy";
import FileItem from "../FileItem/FileItem";
import { FileInfo } from "../types";

interface FileListProps {
    existingFiles: FileInfo[];
    folderId: string;
    onFileDelete: (filename: string) => void;
    onFileClick: (fileInfo: FileInfo) => void;
    isSavedDocuments?: boolean;
}

const FileList: React.FC<FileListProps> = ({ existingFiles, folderId, onFileDelete, onFileClick, isSavedDocuments }) => {
    return (
        <Box className="file-list" sx={{ width: "100%" }}>
            <List sx={{ width: "100%", padding: 0 }}>
                {existingFiles.map(file => (
                    <FileItem key={file.id} file={file} onFileDelete={onFileDelete} onFileClick={onFileClick} isSavedDocument={isSavedDocuments} />
                ))}
            </List>
        </Box>
    );
};

export default FileList;
